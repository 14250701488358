@import "~rsuite/dist/rsuite-no-reset.css";

* {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

html,
body {
  background-color: #f7f8fa;
  overflow-x: hidden;
}

.mb-5 {
  margin: 20px 0;
}

.error-message {
  color: red;
  font-size: 14px;
  margin: 6px 0;
}

@media print {
  body * {
    visibility: hidden; /* Esconde todos os elementos da página */
  }

  .printable,
  .printable * {
    visibility: visible; /* Mostra apenas a div a ser impressa */
  }

  .printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  /* Outros ajustes globais de impressão podem ser feitos aqui, como esconder elementos desnecessários */
  /* Você pode esconder o botão de imprimir, por exemplo */
  .no-print {
    display: none !important;
  }
}

.rotate {
  animation: spin 1s linear forwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
